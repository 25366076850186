import React from "react";
import background from "../assets/images/background1.png";
import { motion } from "framer-motion";
import useDocumentTitle from "../utility/useDocumentTitle";

// import { Element } from "react-scroll";

function About(): JSX.Element {
  const containerVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.4, duration: 1.5 },
    },
    exit: {
      x: "-100vh",
      transition: { ease: "easeInOut" },
    },
  };

  function Page(props: any) {
    const titlePrefix = "/ About";
    useDocumentTitle(`${props.title}${titlePrefix}`);
    return <h2>{props.content}</h2>;
  }

  return (
    <motion.div
      id="about"
      variants={containerVariant}
      initial="hidden"
      animate="visible"
      exit="exit"
      data-testid="home"
    >
      <Page title="SABAOTH  " />
      <div className="aboutTitle"><h1>ABOUT US</h1></div>
      <div style={{ background: "#234497" }} className="about-card text-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title">
                <h1>our mission</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <p>
                  Empowering Global Palates, Cultivating Trust: At BridgeAfrica,
                  we are on a mission to change the world's perception of
                  Nigerian agricultural commodities.'' Our dedication is
                  unwavering: we will eliminate the stigma associated with
                  Nigerian products by creating an unmatched quality level. We
                  promise to give only the best, ethically obtained hibiscus,
                  exceeding international standards and maintaining a long-term
                  and trustworthy supply chain. We want to be the catalyst for
                  change in the worldwide commodities market, one premium petal
                  at a time, through honesty, ethics, and a passion for quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ background: `url(${background})` }}
        className="about-card text-white"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title">
                <h1>our vision</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <p>
                  Our mission at BridgeAfrica is to rewrite the story of
                  Nigerian commodities, making them into a global emblem of
                  excellence. Through an unrelenting commitment to the highest
                  quality standards, we hope to be a catalyst for change,
                  removing the stigma associated with Nigerian products. We
                  envision a world where our premium Nigerian commodities are
                  more than just products, but a sign of confidence,
                  trustworthiness, and the rich tapestry of Nigeria's
                  agricultural prowess, through sustainable practises, ethical
                  sourcing, and cutting-edge quality assurance. Join us on this
                  adventure as we create a global awareness for the finest
                  hibiscus, fostering a future when the term "Nigerian
                  Commodities" is synonymous with outstanding excellence on
                  every continent."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-card">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title">
                <h1>our values</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="details">
                <div>
                  <p>
                    BridgeAfrica is dedicated to two goals: improving the
                    worldwide status of Nigerian agricultural commodities and
                    empowering local farmers. Our mission is to dispel the
                    negative perception of Nigerian products by rigorously
                    adhering to stringent quality standards and ensuring
                    uncompromising perfection in every shipment.
                  </p>
                  <p>
                    We believe in cultivating a mutually beneficial connection
                    with our local farmers, recognising them as the foundation
                    of our purpose. We seek to improve the livelihoods of
                    individuals who farm our land through fair trade practises,
                    long-term collaborations, and community empowerment
                    projects. By prioritising the greatest quality in our
                    agricultural goods, we hope to not only change worldwide
                    opinions but also have a positive impact at the grassroots
                    level.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        Quality Service, Quality Products
      </footer>
    </motion.div>
  );
}

export default About;
