import React,{useEffect} from "react";
import "./App.css";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Home from "./pages/home";
import Product from "./pages/product";
import Contact from "./pages/contact";
import Header from "./components/header/header";
import 'bootstrap/dist/css/bootstrap.min.css'; 
import AOS from 'aos'; 
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.refresh();
  })

  return (
    <BrowserRouter>
      <Header />
      <AnimatePresence>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}/>
            <Route path="/product" element={<Product />} />
            <Route path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default App;
