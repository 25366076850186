import React from "react";
import background from "../../asset/image/Group-12-2.png";
import { motion } from "framer-motion";
import useDocumentTitle from "../utility/useDocumentTitle";

// import { Element } from "react-scroll";

function Product(): JSX.Element {
  const containerVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.4, duration: 1.5 },
    },
    exit: {
      x: "-100vh",
      transition: { ease: "easeInOut" },
    },
  };

  const products = [
    {
      title: "sesame seed",
      body: "Nigerian sesame seeds are a nutrient powerhouse, with high quantities of protein, healthy fats, vitamins, and minerals.The inclusion of heart-healthy fats like monounsaturated and polyunsaturated fats promotes cardiovascular health.Nigerian sesame seeds are available in ivory and black forms, providing adaptability for a variety of culinary uses. These seeds, known for their high oil content, are a valuable source of sesame oil, which is prized for its distinct flavour and health advantages.Nigerian sesame seeds' particular flavour has led to increased demand in worldwide cuisines ranging from traditional recipes to creative gourmet innovations.Nigerian sesame seeds are positioned to satisfy expanding worldwide demand, overcoming any past stigma associated with the sourcing location, thanks to a commitment to quality and adherence to international standards.",
    },
    {
      title: "ginger",
      body: "Nigerian ginger is noted for its pungent and aromatic properties, which give culinary creations a robust and spicy flavour.Nigerian ginger has anti-inflammatory and antioxidant qualities due to its high concentration of bioactive components such as gingerol.The ginger benefits from excellent conditions when grown in Nigeria's nutrient-rich soils, which improves its overall quality.Gingerol, a bioactive molecule found in Nigerian ginger, has powerful anti-inflammatory properties that aid in the decrease of inflammation and related diseases.Regular ginger eating may help to enhance heart health by improving blood circulation and cholesterol levels.Nigerian ginger is a staple spice in many global cuisines, which contributes to its continual culinary demand.",
    },
    {
      title: "soya beans",
      body: "Nigerian soybeans are renowned for their exceptionally high protein content, making them a crucial source of plant-based protein.Used in various forms, including whole beans, tofu, soy milk, and more, Nigerian soybeans are incredibly versatile in culinary applications.Soybeans are a sustainable crop, enhancing soil fertility through nitrogen fixation, making them beneficial for agricultural ecosystems.Soybeans contain calcium and other minerals essential for bone health, making them beneficial for overall skeletal well-being.Soybean oil has diverse industrial applications, including in the production of biodiesel and various manufacturing processes, contributing to its demand in non-food sectors.",
    },
  ];

  function Page(props: any) {
    const titlePrefix = "/ Product";
    useDocumentTitle(`${props.title}${titlePrefix}`);
    return <h2>{props.content}</h2>;
  }

  return (
    <motion.div
      className="home"
      id="product"
      variants={containerVariant}
      initial="hidden"
      animate="visible"
      exit="exit"
      data-testid="home"
    >
      <Page title="SABAOTH  " />
      <div className="productTitle"><h1>OUR PRODUCTS</h1></div>
      <div>
        <div className="product-card">
          <div className="container">
            <div className="row">
              <div className="col-lg-6"><div className="title"><h1>hibiscus</h1></div></div>
              <div className="col-lg-6">
                <p>
                  Immerse yourself in the brilliant appeal of Nigerian Hibiscus,
                  where nature's artistry shines through each petal. Our
                  hibiscus blooms have a rich crimson hue, a fascinating
                  perfume, and a zesty, stimulating flavour profile. Our
                  hibiscus is grown in the lush soils of Nigeria and nurtured
                  under the African sun, resulting in a bloom of exceptional
                  beauty and vitality.
                </p>
                <p>
                  Each hibiscus blossom represents a perfect blend of tradition
                  and quality, having been harvested with care and precision.
                  Our hibiscus is a monument to the devotion to perfection that
                  distinguishes our brand, from the moment the bud unfurls to
                  display its magnificent crimson petals to the rigorous drying
                  process that maintains its essence.
                </p>
              </div>
            </div>
          </div>
        </div>

        {products.map((product) => (
          <div className="product-card">
            <div className="container">
              <div className="row">
                <div className="col-lg-6"><div className="title"><h1>{product.title}</h1></div></div>
                <div className="col-lg-6"><p>{product.body}</p></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
}

export default Product;
