import React from "react";
import { motion } from "framer-motion";
import useDocumentTitle from "../utility/useDocumentTitle";
import icon1 from "../assets/images/icon1.svg";
import icon2 from "../assets/images/icon2.svg";
import icon3 from "../assets/images/icon3.svg";

// projects data
const project = [
  {
    icon: icon1,
    content: "Over 5000 acres of land",
  },
  {
    icon: icon2,
    content: "Over 1000 farmers",
  },
  {
    icon: icon3,
    content: "One Goal",
  },
];

function Home(): JSX.Element {
  const containerVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.4, duration: 1.5 },
    },
    exit: {
      x: "-100vh",
      transition: { ease: "easeInOut" },
    },
  };

  function Page(props: any) {
    const titlePrefix = "/ Home";
    useDocumentTitle(`${props.title}${titlePrefix}`);
    return <h2>{props.content}</h2>;
  }

  return (
    <motion.div
      className="home"
      id=""
      variants={containerVariant}
      initial="hidden"
      animate="visible"
      exit="exit"
      data-testid="home"
    >
      <Page title="SABAOTH  " />
      {/* banner */}
      <section className="banner">
        <div className="container">
          <div className="row justify-content-end">
            <div
              className="col-md-6 d-flex justify-content-end mt-4"
              style={{ float: "right" }}
            >
              <motion.div
                className="textStyle text-white"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <h1>
                  5000 Tons Of <br />
                  <span>Hibiscus Flower</span>
                  <br /> Waiting <br /> For You!!
                </h1>
                <p>
                  Transforming the Global Perception: <br /> Unveiling Premium
                  Nigerian Hibiscus, Committed to <br /> Unrivaled Quality
                  Standard
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* projects */}
      <section className="container project">
        <div className="row" style={{ gap: "130px" }}>
          {project.map((item, index) => (
            <div
              className="col-lg-4 col-md-6"
              style={{ width: "350px" }}
              key={index}
              // data-aos="fade-down"
              // data-aos-duration="1000"
              // data-aos-offset="200"
            >
              <div className="card">
                <div className=""></div>
                <div className="">
                  <img src={item.icon} />
                </div>
                <div className="">
                  <p>{item.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* problems */}

      <section className="solution">
        <div className="container">
          <section className="problems">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h2>
                    We Understand The <br /> Problem
                  </h2>
                </div>
                <div className="col-lg-6">
                  <p>
                    More than 70% of Nigeria's agricultural exports are
                    restricted. Food safety, technological difficulties,
                    noncompliance with best practises, and disregard for basic
                    standards are among the reasons given for the rejection.
                    This results in financial loss, loss of trust, and
                    stigmatisation of Nigerian products.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <h1>Our Solutions</h1>
          <div className="row">
            <div className="col-lg-4">
              <h4>EDUCATION</h4>
              <p>
                We understand that the quality of our agricultural goods starts
                with the farmers who cultivate the land. To remove the stigma
                associated with Nigerian products, we are committed to elevating
                not only the finished product but the entire agricultural
                process.
              </p>
              <p>
                Beyond providing the highest quality hibiscus, we are committed
                to establishing a sustainable and ethical farming ecosystem. We
                provide local farmers with information and resources for
                sustainable farming practises through comprehensive education
                efforts.
              </p>
            </div>
            <div className="col-lg-4">
              <h4>QUALITY CONTROL</h4>
              <p>
                To confront and remove existing stigmas, we have devised a
                comprehensive plan to ensure that our commodities meet the
                highest worldwide standards. Our commitment to excellence is
                founded on the following important principles:In order to ensure
                accountability and transparency, we are using sophisticated
                traceability systems and meticulous documentation practises to
                follow the travel of our products from farm to market.Engaging
                recognised third-party auditing agencies to undertake frequent
                assessments and audits of our facilities, practises, and
                products in order to provide an unbiased review of our quality
                standards adherence, amongst others
              </p>
            </div>
            <div className="col-lg-4">
              <h4>SUPPLY</h4>
              <p>
                BridgeAfrica understands the critical relevance of smooth supply
                and delivery operations in improving the worldwide status of
                Nigerian agricultural commodities. Our dedication to removing
                existing stigmas and providing the highest quality products is
                supported by a thorough plan that ensures an efficient and
                dependable worldwide supply chain.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* small caption */}
      <section className="home-caption container text-center mt-5" >
        <h1>BRIDGING THE GAP</h1>
        <h1>
          WE ARE YOUR GATEWAY TO QUALITY <br /> ASSURED COMMODITIES FROM AFRICA
        </h1>
      </section>

      {/* ceo quote */}
      <section className="quotes">
        <div className="container">
          <p className="details">
            Breaking the Stigma, Raising the Bar: Our objective is to rewrite
            the story of Nigerian products. We're not just selling hibiscus;
            we're creating trust in every petal with unrelenting dedication and
            painstaking attention to quality clearance. pick assurance, pick
            excellence—your journey to premium Nigerian hibiscus begins here
          </p>
          <p className="autor">
            Oyinebifinide Timizuo <br /> CEO BridgeAfrica
          </p>
        </div>
      </section>
    </motion.div>
  );
}

export default Home;
