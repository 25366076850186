import React from "react";
import { motion } from "framer-motion";
import useDocumentTitle from "../utility/useDocumentTitle";
import { Form, Button } from "react-bootstrap";

// import { Element } from "react-scroll";

function Contact(): JSX.Element {
  const containerVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { delay: 0.4, duration: 1.5 },
    },
    exit: {
      x: "-100vh",
      transition: { ease: "easeInOut" },
    },
  };

  function Page(props: any) {
    const titlePrefix = "/ Contact";
    useDocumentTitle(`${props.title}${titlePrefix}`);
    return <h2>{props.content}</h2>;
  }

  return (
    <motion.div
      id="contact"
      variants={containerVariant}
      initial="hidden"
      animate="visible"
      exit="exit"
      data-testid="home"
    >
      <Page title="SABAOTH  " />
      {/* banner */}
      <div className="contactTitle"><h1>CONTACT US</h1></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <h1 style={{ color: "#2b4a77" }} className='title'>Questions?  Contact us :)</h1>
            <Form>
              <Form.Group controlId="formName" className="formName">
                <Form.Control type="text" placeholder="Name" name="name" />
                <Form.Control type="email" placeholder="Email" name="email" />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  name="subject"
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Message"
                  name="message"
                />
              </Form.Group>

                <Button variant="primary" type="submit">
                  Send
                </Button>
            </Form>
          </div>
          <div className="col-lg-8">
            <img src={require("../assets/images/contactImage.png")} />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
