// NavbarComponent.tsx
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.jpg";

const NavbarComponent: React.FC = () => {
  return (
    <div className="nav">
      <Navbar expand="lg">
        <div className="container-fluid">
          <Navbar.Brand href="/" className="mr-auto">
            <img src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {[
                { name: "HOME", route: "/" },
                { name: "ABOUT US", route: "/about" },
                { name: "OUR PRODUCTS", route: "/product" },
                { name: "CONTACT US", route: "/contact" },
              ].map((link) => (
                <li key={link.name}>
                  <NavLink to={link.route}>{link.name}</NavLink>
                </li>
              ))}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
